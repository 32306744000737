import { UpsellCore, Customer, Order, OffersConfig } from './upsell.core';

declare global {
	interface Window {
		Shopify: any;
    }
}

class UpsellShopify extends UpsellCore {
	private isShopifyPage(): boolean {
		return typeof window.Shopify !== 'undefined';
	}

	private isShopifyCheckoutPage(): boolean {
		if (((window.location.pathname.indexOf("thank_you") > -1) || (window.location.pathname.indexOf("orders") > -1))
			&& this.isShopifyPage()
			&& (typeof window.Shopify.checkout !== 'undefined')
			&& window.Shopify.checkout
			&& window.Shopify.checkout.order_id) {
			return true;
		}

		return false;
	}

	public async start(): Promise<void> {
		try {
			if (!this.isShopifyPage()) {
				throw new Error('Not on a Shopify page');
			}

			if (!this.isShopifyCheckoutPage()) {
				throw new Error('Not on a Shopify checkout page or not signed-in');
			}

			let shop = window.Shopify.shop;

			let order_info: Order = { id: null };
			if (window.Shopify.checkout && window.Shopify.checkout.order_id) {
				order_info.id = window.Shopify.checkout.order_id;
			}

			let customer_info: Customer = {
				email: window.Shopify.checkout.email
			};

			if (typeof window.Shopify.checkout.billing_address !== 'undefined') {
				customer_info.first_name = window.Shopify.checkout.billing_address.first_name;
				customer_info.last_name = window.Shopify.checkout.billing_address.last_name;
			}

			this.init(shop);
			let settings = await this.loadSettings();
			if (settings?.enabled) {
				let config: OffersConfig = {
					order_info: order_info,
					customer_info: customer_info
				};
				this.showOffers(config);
			}
		}
		catch (e) {
			console.log(e.message);
        }
	}
}

export default (function () {
	let api = new UpsellShopify();
	api.start();
})();